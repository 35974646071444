.bmw-page {
  position: relative;
  .first-row {
    height: 960px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    .first-img {
      // width: 100%;
      height: 100%;
      object-fit: contain;
      margin: auto;
      // animation: animate1 5s ease-in-out infinite;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .mobile-first-row {
    height: 960px;
    overflow: hidden;
    display: none;
    justify-content: center;
    align-items: center;
    .first-img {
      // width: 100%;
      height: 100%;
      object-fit: contain;
      margin: auto;
      // animation: animate1 5s ease-in-out infinite;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .pc-second-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 960px;
    justify-content: center;
    overflow: hidden;
    .first-img {
      height: 100%;
      object-fit: contain;
      margin: auto;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .second-row {
    display: none;
    flex-wrap: wrap;
    align-items: center;
    height: 960px;
    justify-content: center;
    overflow: hidden;
    video {
      width: 100%;
      margin: auto;
      object-fit: contain;
    }
  }
  .third-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 960px;
    justify-content: center;
    overflow: hidden;
    video {
      width: 100%;
      margin: auto;
      object-fit: contain;
    }
  }
  .pc-fourth-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 960px;
    justify-content: center;
    overflow: hidden;
    video {
      width: 100%;
      margin: auto;
      object-fit: contain;
    }
  }
  .fourth-row {
    display: none;
    flex-wrap: wrap;
    align-items: center;
    height: 960px;
    justify-content: center;
    overflow: hidden;
    video {
      width: 100%;
      margin: auto;
      object-fit: contain;
    }
  }
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    .top-title {
      color: #fff;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      height: 53px;
      align-items: center;
      font-size: 17px;
      font-weight: 650;
    }
    .top-p {
      color: #fff;
      font-size: 15px;
      padding: 0 20px;
      font-weight: 400;
    }
    .logo {
      margin: 30px 0 0;
    }
    h1 {
      color: #fff;
      margin: 60px 0 0;
      font-size: 33px;
      text-align: center;
    }
    h2 {
      color: #fff;
      margin: 50px 0 0;
      font-size: 28px;
      text-align: center;
    }
    .btn {
      position: absolute;
      bottom: 20px;
      color: #fff;
      font-weight: 550;
      width: 330px;
      padding: 15px 20px;
      border-radius: 5px;
      background-color: rgb(0, 0, 0);
      display: flex;
      overflow: hidden;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
      .double {
        padding-left: 10px;
        animation: animate2 0.5s ease-in-out infinite;
      }
    }
    .allowLeft {
      position: absolute;
      top: 20px;
      left: 50px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

@keyframes animate1 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes animate2 {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(20px);
  }
}

@media screen and (max-width: 1900px) {
  .bmw-page {
    .first-row {
      .first-img {
        width: auto !important;
        height: 100% !important;
      }
    }
    .mobile-first-row {
      .first-img {
        width: auto !important;
        height: 100% !important;
      }
    }
    .second-row {
      video {
        width: auto !important;
        height: 100% !important;
      }
    }
    .third-row {
      video {
        width: auto !important;
        height: 100% !important;
      }
    }
    .fourth-row {
      video {
        width: auto !important;
        height: 100% !important;
      }
    }
    .pc-fourth-row {
      video {
        width: auto !important;
        height: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .bmw-page {
    .mask {
      padding: 30px 20px;
      .allowLeft {
        top: 40px;
        left: 30px;
      }
      .top-title {
        font-size: 15px;
        margin-left: 30px;
        height: 50px;
        .top-p {
          font-size: 13px;
        }
      }
    }
  }
}
@media screen and (max-width: 430px) {
  .bmw-page {
    .pc-fourth-row {
      display: none !important;
    }
    .fourth-row {
      display: flex !important;
    }
    .pc-second-row {
      display: none !important;
    }
    .second-row {
      display: flex !important;
    }
    .mobile-first-row {
      display: flex !important;
    }
    .first-row {
      display: none !important;
    }
  }
}
