.home {
  width: 100%;
  // height: 100%;
  background: url(../image/bg.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  .home-page {
    width: 700px;
    // height: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: relative;
    .head {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      z-index: 1;
      height: 60px;
      .img {
        width: 700px;
        position: absolute;
        background-color: #fff;
        padding: 5px 0;
        img {
          // width: 100%;
          height: 60px;
        }
        .web-img {
          display: none !important;
        }
        .animate-button {
          position: absolute;
          top: 25%;
          right: 20px;
          width: 100px;
          height: 35px;
          background-color: rgb(210, 15, 39);
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 650;
          color: #fff;
          animation: animate1 2s infinite;
          -webkit-animation: animate1 2s infinite;
        }
      }
    }
    .content {
      background-color: #fff;
      padding-top: 70px;
      .profile-box {
        padding: 20px 20px 0;
        display: flex;
        align-items: center;
        .profile-img {
          width: 40px;
          height: 40px;
          border-radius: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          img {
            height: 100%;
          }
        }
        .info-box {
          margin-left: 15px;
          .name {
            font-weight: 600;
            font-size: 14px;
            margin: 0 0 3px;
          }
          .time {
            font-size: 13px;

            display: flex;
            align-items: center;
            span {
              padding: 0 5px;
              color: #737b8b;
            }
          }
        }
      }
      .story-content {
        margin: 20px 0 0;
        padding: 0 20px;
        p {
          line-height: 30px;
          h1 {
            line-height: 40px;
            margin: 0;
          }
          img {
            max-width: 100%;
            margin: 5px 0 0;
            height: auto;
          }
        }
      }
      .foot {
        margin: 0 20px;
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgb(229, 231, 235);
        .profile {
          display: flex;
          align-items: center;
          .img {
            width: 32px;
            height: 32px;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
            overflow: hidden;
            img {
              height: 100%;
            }
          }
          .info {
            font-size: 15px;
            span {
              font-weight: 650;
            }
          }
        }
        .right {
          display: flex;
          li {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
      .comment-content {
        .comment-head {
          margin: 0 20px;
          padding: 15px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid rgb(229, 231, 235);
          .zan {
            display: flex;
            align-items: center;
            span {
              color: rgb(115, 123, 139);
              padding: 0 5px;
              font-size: 13px;
            }
          }
          .right {
            span {
              color: rgb(115, 123, 139);
              padding-left: 20px;
              font-size: 13px;
            }
          }
        }
        .comment-ul {
          padding: 15px 20px 0;
          .comment-li {
            margin: 0 0 18px;
            display: flex;
            .profile-img {
              width: 30px;
              height: 30px;
              border-radius: 25px;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 10px;
              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
            .li-txt {
              max-width: 90%;
              .name {
                font-size: 12px;
                font-weight: 700;
                margin: 0 0 10px;
              }
              .content-txt {
                padding: 10px;
                background-color: #f0f2f5;
                border-radius: 10px;
                p {
                  line-height: 15px;
                  white-space: pre-wrap;
                  font-size: 12px;
                  img {
                    max-width: 300px;
                    display: block;
                    margin: 5px 0;
                  }
                }
              }
              .time-box {
                margin: 10px 0 0;
                display: flex;
                align-items: center;
                .time-p {
                  padding-left: 10px;
                  font-size: 12px;
                  color: #737b8b;
                }
                .num {
                  padding-left: 10px;
                  font-size: 11px;
                  color: #737b8b;
                }
              }
            }
          }
        }

        .love {
          width: 30px;
          height: 30px;
          position: relative;
          cursor: pointer;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background: url(../image/web_heart_animation.png) 0 0 no-repeat;
            background-size: cover;
            height: 30px;
            width: 30px;
          }
        }
        .active {
          &::after {
            -webkit-animation: heart-burst steps(28) 1s 1 both;
            animation: heart-burst steps(28) 1s 1 both;
          }
        }
      }
    }
  }
}

.cart-model {
  position: fixed;
  bottom: 20px;
  left: 15px;
  width: 340px;
  // height: 130px;
  z-index: 1;
  background-color: #ebf5ff;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 8px 10px -6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 8px;
  transition: all 3s;
  display: none;
  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    &:hover {
      cursor: pointer;
    }
  }
  .row {
    display: flex;
    align-items: center;
    .left {
      width: 80px;
      height: 70px;
      background-color: #fff;
      border-radius: 8px;
      overflow: hidden;
      .swiper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        .swiper-wrapper {
          .swiper-slide {
            background-size: cover;
            background-position: center center;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            img {
              max-width: 100%;
              // max-height: 100%;
            }
          }
        }
      }
    }
    .info-detail {
      margin-left: 10px;
      .title {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
      .txt {
        margin: 5px 0 0;
        font-size: 14px;
        line-height: 20px;
      }
      .tip {
        margin: 5px 0 0;
        font-size: 14px;
        color: #ff5c66;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}

.cart-show {
  display: block !important;
}

@-webkit-keyframes heart-burst {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

@keyframes heart-burst {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

@keyframes animate1 {
  14% {
    transform: scale(1.3);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.3);
  }
  70% {
    transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .home {
    .home-page {
      width: 100%;
      .head {
        .img {
          width: 100%;
          padding: 10px 0;
          .pc-img {
            display: none !important;
          }
          .web-img {
            display: block !important;
            height: 50px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .home {
    .home-page {
      .content {
        padding-top: 50px !important;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .home {
    .home-page {
      .content {
        .comment-content {
          .comment-ul {
            .comment-li {
              .li-txt {
                .content-txt {
                  p {
                    img {
                      max-width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
