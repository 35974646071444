.model-page {
  .header {
    height: 44px;
    background-color: rgb(0, 90, 87);
    color: #fff;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  .logo-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    font-size: 24px;
    color: #4e4681;
  }
  .model-box {
    .choose-title {
      margin: 0 0 8px;
      font-size: 16px;
      font-weight: 600;
    }
    .choose-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .left {
        width: 100%;
        padding: 20px;
        .title {
          font-size: 20px;
          margin: 0 0 5px;
          font-weight: 500;
        }
        .star {
          display: flex;
          align-items: center;
          margin: 15px 0 0;
          p {
            font-weight: 600;
          }
        }
        .price {
          margin: 16px 0;
          .title {
            margin: 0 0 8px;
            font-size: 16px;
            font-weight: 600;
          }
          .value {
            display: flex;
            align-items: end;
            font-weight: 600;
            .original {
              text-decoration: line-through;
              margin-right: 10px;
              color: #999999;
              font-size: 17px;
            }
            .current {
              margin-right: 10px;
              font-size: 22px;
            }
            .save {
              background-color: rgb(245, 166, 35);
              padding: 5px;
              font-size: 13px;
            }
          }
        }
        .sku {
          .sku-title {
            margin: 0 0 10px;
            font-size: 16px;
            font-weight: 600;
          }
          .sku-img {
            width: 45px;
            height: 45px;
            margin: 0 0 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            box-shadow: 0 0 0 2px #f0a73e;
            img {
              max-width: 100%;
            }
          }
        }
        .ship-txt {
          margin: 20px 0;
          .ship-li {
            display: flex;
            margin: 20px 0 0;
            flex-direction: column;
            p {
              font-size: 16px;
              color: #666666;
              font-weight: 600;
              line-height: 20px;
            }
          }
        }
        .choose-row {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          li {
            margin: 10px 10px 10px 0;
            border: 1px solid #d2d2d2;
            padding: 8px 12px;
            display: flex;
            justify-content: center;
            color: rgb(57, 60, 65);
            font-size: 12px;
            font-weight: 600;
            text-align: center;
            display: flex;
            align-items: center;
          }
          .active {
            background-color: rgba(0, 90, 87, 1);
            color: #fff;
          }
          img {
            width: 40px;
            height: 40px;
          }
          .disabled {
            li {
              color: #acacac;
              border: 1px solid #acacac;
              opacity: 0.7;
              &:hover {
                cursor: not-allowed;
              }
            }
          }
        }
        .choose-title {
          margin: 10px 0 0;
          display: flex;
          align-items: center;
          span {
            text-decoration: underline;
            padding: 0 5px;
          }
        }
        .quantity {
          margin: 16px 0 0;
          .title {
            margin: 0 0 8px;
            font-size: 16px;
            font-weight: 600;
          }
          .numberBar {
            height: 40px;
            width: 120px;
            background-color: #fff;
            border: 1px solid #e1e2e8;
            display: flex;
            align-items: center;
            .add {
              width: 40px;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .sub {
              width: 40px;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .num {
              width: 40px;
              height: 100%;
              background-color: #e1e2e8;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 600;
            }
          }
        }
      }
      .right {
        width: 100%;
        .mySwiper {
          width: 100%;
          img {
            width: 100%;
          }
        }
        .myThumbs {
          margin: 16px 20px 0;
          .mySwiperThumbs {
            height: 100%;
            .swiper-slide {
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              width: 60px;
              height: 60px;
              img {
                max-width: 100%;
              }
            }
            .swiper-slide-thumb-active {
              border: 1px solid #708090;
              border-radius: 5px;
            }
          }
        }
      }
    }
    .btn {
      margin: 0 20px;
      padding: 15px;
      background-color: rgb(0, 90, 87);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      color: #fff;
      box-shadow: RGB(37 42 50 / 32%) 0 1px 4px 1px;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
    .box {
      margin: 20px 0 0;
      width: 100%;
      padding: 0 10px;
      img {
        max-width: 100%;
      }
    }
  }
  .descrption-box {
    padding: 20px 20px 0;
    .descrption-row {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      border-bottom: 1px solid rgb(228, 228, 231);
    }
    .descrption-content {
      display: none;
      color: #474747;
      h2 {
        text-align: center;
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
        margin: 0 0 20px;
      }
      h4 {
        margin: 0 0 20px;
        font-weight: normal;
        line-height: 24px;
      }
      p {
        text-align: center;
        line-height: 20px;
        font-weight: 500;
        font-size: 15px;
        margin: 16px 0;
        span {
          text-decoration: underline;
        }
      }
      .table {
        margin: 50px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .descrption-show {
      display: block !important;
    }
    .descrption-border {
      border: 0 !important;
    }
  }
  .shipping-box {
    padding: 0 20px;
    .shipping-row {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      border-bottom: 1px solid rgb(228, 228, 231);
    }
    .shipping-show {
      display: block !important;
    }
    .shipping-border {
      border: 0 !important;
    }
    .shipping-content {
      display: none;
      p {
        padding: 10px 0;
        line-height: 22px;
      }
    }
  }
  .return-box {
    padding: 0 20px;
    .return-row {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      border-bottom: 1px solid rgb(228, 228, 231);
    }
    .return-show {
      display: block !important;
    }
    .return-border {
      border: 0 !important;
    }
    .return-content {
      display: none;
      p {
        padding: 10px 0;
        line-height: 22px;
      }
    }
  }
  .wrapper {
    padding: 20px;
    // margin: 0 0 50px;
    h2 {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.5;
      padding: 0 0 10px;
      border-bottom: 1px solid #474747;
    }
    p {
      line-height: 25px;
      font-weight: 600;
      margin: 20px 0;
      color: #474747;
    }
    .box {
      margin: 20px 0;
      position: relative;
      img {
        width: 100%;
      }
      .txt {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        .title {
          font-weight: 600;
          font-size: 24px;
          text-align: center;
          margin: 0 0 20px;
        }
        .desc {
          font-size: 17px;
          line-height: 20px;
          font-weight: 600;
          text-align: center;
        }
      }
    }
    .text-contain {
      margin: 40px 0;
      padding: 10px;
      background-color: #d098bc;
      color: #fff;
      text-align: center;
    }
  }
}

.sizeModel {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 10000;
  justify-content: center;
  align-items: center;
  padding: 30px 15px;
  .sizeModel-core {
    background-color: #fff;
    width: 100%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      box-sizing: border-box;
      padding: 0 32px;
      border-bottom: 1px solid #eee;
      font-size: 24px;
    }
    .body-contain {
      padding: 30px;
      overflow-y: auto;
      overflow-x: hidden;
      box-sizing: border-box;
      .img-box {
        img {
          width: 100%;
        }
      }
    }
  }
}

.sizeModel-show {
  display: flex;
  animation: appsizechartFadeOut 1000ms forwards;
  .sizeModel-core {
    animation: appBounceInDown 1000ms forwards;
  }
}
.sizeModel-hidden {
  display: flex;
  animation: appsizechartFadeIn 1000ms forwards;
  .sizeModel-core {
    animation: appBounceOutUp 1000ms forwards;
  }
}

@keyframes appBounceOutUp {
  0% {
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

@keyframes appBounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateY(30px);
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes appSlideOutDown {
  0% {
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

@keyframes appSlideInUp {
  0% {
    opacity: 0;
    transform: translateY(1000px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appSlideOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes appSlideInLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes appsizechartFadeOut {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

@keyframes appsizechartFadeIn {
  0% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}
