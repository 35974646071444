.payment-container {
  width: 100%;
  .card-title {
    margin: 0 0 10px;
    color: #525252;
    font-weight: 600;
  }
  .card-row {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    margin: 0 0 20px;
    .full {
      width: 100%;

      .input-box {
        position: relative;
        input {
          width: 100%;
          padding: 0 15px;
          height: 45px;
          border: 1px solid rgba(217, 217, 217, 1);
          border-radius: 5px;
          letter-spacing: 1px;
          &:focus {
            outline: none;
            background-color: #fff;
            border: 3px solid rgba(59, 130, 246, 0.5);
          }
          &::placeholder {
            color: #686868;
            font-size: 15px;
          }
        }
        .card-icon {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          &::after {
            content: "";
            display: block;
            background: #ffffff;
            height: 100%;
            position: absolute;
            box-shadow: 0 0 12px 6px #fff;
            top: 0;
            left: 0px;
            transform: rotate(30deg);
            animation: 2s move infinite linear;
          }
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
    .half {
      width: 48%;
      input {
        width: 100%;
        padding: 0 15px;
        height: 45px;
        border: 1px solid rgba(217, 217, 217, 1);
        border-radius: 5px;
        letter-spacing: 1px;
        margin: 0 0 10px;
        &:focus {
          outline: none;
          background-color: #fff;
          border: 3px solid rgba(59, 130, 246, 0.5);
        }
        &::placeholder {
          color: #686868;
          font-size: 15px;
        }
      }
    }
  }
}

.input-error {
  border-color: red !important;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  //   margin-top: 0.25rem;
}

.default-icons {
  display: flex;
  gap: 10px; // 图标之间的间距
}

@keyframes move {
  0% {
    right: 100%;
  }
  100% {
    right: 0;
  }
}
